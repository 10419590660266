import { writable } from 'svelte/store';

// Export the store so we can use it in any component
export const textPromptDialog = writable(null);

export async function promptText(message, title = '', mods = {}) {
	let resolve;

	const promise = new Promise((_resolve) => {
		resolve = _resolve;
	});

	/**
	 * Possible Mods:
     * - label
     * - placeholder
	 */

	textPromptDialog.set({
		message,
		title,
		mods,
		resolve,
	});

	return promise;
}
